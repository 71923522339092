<template>
  <UpdatePassword />
</template>

<script>
import UpdatePassword from "@/components/User/UpdatePassword/UpdatePassword";
export default {
  name: "UpdatePasswordContainer",
  components: {
    UpdatePassword,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module src="./style.css"></style>
