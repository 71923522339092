<template>
  <div :class="$style.content">
    <div :class="$style.updatePasswordTitle">Alterar senha</div>
    <div :class="$style.firstColumn">
      Definir uma nova senha para os próximos acessos.
    </div>
    <a-row>
      <a-col :span="isDesktop ? 12 : 24">
        <a-form
          layout="vertical"
          :model="formState"
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item>
            <a-input
              :class="$style.rounded"
              v-model:value="formState.password"
              type="password"
              placeholder="Nova senha"
              size="large"
              :rules="{
                required: true,
                type: 'password',
                message: 'Digite uma senha!',
                trigger: 'change',
              }"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              :class="$style.rounded"
              v-model:value="formState.passwordConfirmation"
              type="password"
              placeholder="Confirme a nova senha"
              size="large"
              :rules="{
                required: formState.passwordConfirmation ? true : false,
                type: 'password',
                message: 'Digite a confirmação da senha!',
                trigger: 'change',
              }"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              html-type="submit"
              type="primary"
              :class="$style.saveButton"
              :loading="isLoading"
              :disabled="
                formState.password === '' ||
                formState.password !== formState.passwordConfirmation
              "
            >
              Salvar
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { LockOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, reactive } from "vue";
import { mapActions, mapState } from "vuex";
import { isDesktop, isMobile } from "@/utils/isAgent";
export default defineComponent({
  setup() {
    const formState = reactive({
      password: "",
      passwordConfirmation: "",
    });

    return {
      formState,
      isDesktop,
      isMobile,
    };
  },
  components: {
    LockOutlined,
  },
  computed: {
    ...mapState("user", ["isLoading"]),
  },
  methods: {
    ...mapActions("user", ["updatePassword"]),

    async handleFinish() {
      try {
        const data = {
          password: this.formState.password,
        };
        await this.updatePassword(data);
        this.formState.password = this.formState.passwordConfirmation = "";
        message.success("Senha alterada com sucesso!");
      } catch (e) {
        message.error("Não foi possivel fazer login.");
      }
    },
  },
});
</script>

<style module src="./style.scss" lang="scss" />
