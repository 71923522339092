<template>
  <UpdatePassword />
</template>
<script>
import { defineComponent } from "vue";
import UpdatePassword from "@/containers/User/UpdatePassword/UpdatePassword";
export default defineComponent({
  components: {
    UpdatePassword,
  },
  setup() {
    return {};
  },
});
</script>
